import React, { useContext } from "react";
import dynamic from "next/dynamic";
import classnames from "classnames";

//Style
import Styles from "./MarketplaceLoadCard.module.scss";

//Constant
import { EXPECTED_AMOUNT_TYPE, LORRY_BODY_TYPE_MAP, NEW_LORRY_TYPES } from "@vahak/core/dist/constants";

//Components
import IfCanAccess from "../../admin/IfCanAccess/IfCanAccess";
import SourceDestination from "../../posting-form/common/src-dest/SourceDestination";
import LineDivider from "@vahak/core-ui/dist/components/LineDivider";
import MarketplaceLoadLorryQuickInfo from "../marketplace-load-lorry-quick-info/MarketplaceLoadLorryQuickInfo";
import Flex from "@vahak/core-ui/dist/layout/Flex";
import LoadingTimeBadge from "../loading-time-badge/LoadingTimeBadge";
import UserProfileInfo from "../user-profile-info/UserProfileInfo";
import ExpectedPriceAndBidNow from "../expected-price-and-bid-now/ExpectedPriceAndBidNow";
import PeopleViewingCount from "../people-viewing-count/PeopleViewingCount";
import Button from "@vahak/core-ui/dist/components/Button";
import Typography from "@vahak/core-ui/dist/components/Typography";
import Dropdown from "@vahak/core/dist/components/dropdown/Dropdown";
import { toast } from "@vahak/core/dist/components/toast/toast";
const Modal = dynamic(() => import("@vahak/core-ui/dist/components/Modal"), { ssr: false });

//Hooks
import { useToggleModalWithLocalState } from "@vahak/core-ui/dist/hooks/useToggleModalWithLocalState";
import { AppContext } from "@vahak/core/dist/app-context";

//Services
import { useDeactivateLoad } from "@vahak/core/dist/_services/deactivateLoadLorry.service";

//Types && constants
import { Colors } from "@vahak/core-ui/dist/constants";
import { ADMIN_OPERATION } from "../../../constants/adminOperation";
import { LoadMarketCard } from "../../../services/load/useGetLoadMarketV2/type";

//Assets
import DotsMoreIcon from "@vahak/core/dist/standard-icons/options.svg";
import VahakAssured from "@vahak/core/dist/icons/vahakAssured.svg";
import VerifiedTag from "@vahak/core/dist/icons/verifiedTag.svg";
import ShareIcon from "@vahak/core/dist/standard-icons/shareIcon.svg";
import CallCircle from "@vahak/core/dist/icons/call-circle.svg";

import AgentBadge from "../../admin/badge/AgentBadge";
import RelevancyTags from "../relevancy-tags/RelevancyTags";
import IconWrapper from "@vahak/core-ui/dist/components/IconWrapper";
import { useGetContactDetails } from "../../../hooks/useGetContactDetails";
import { C2C_REFERENCE_TYPE } from "../../../constants/c2c-reference-type";
import { GenericObject } from "@vahak/core-ui/dist/types";
import { stringOperations } from "@vahak/core-ui/dist/methods";
import { useEventTrackerService } from "@vahak/core/dist/_services";
import Skeleton from "@vahak/core/dist/components/skeleton/Skeleton";
import Badge from "@vahak/core-ui/dist/components/Badge";

interface MarketplaceLoadCardProps {
    id?: string;
    data: LoadMarketCard;
    onBidNowClick: () => void;
    handleClickProfile?: () => void;
    evenSourceScreen?: string;
    mobileResponsive?: boolean;
}

const MarketplaceLoadCard = ({
    id,
    data,
    onBidNowClick,
    handleClickProfile,
    evenSourceScreen,
    mobileResponsive = true
}: MarketplaceLoadCardProps) => {
    const { setShowShareModalPopup, companyId, setShowAppRedirectionPopup } = useContext(AppContext);

    const toggleShareModal = () => {
        setShowShareModalPopup?.({
            isVisible: true,
            mktType: "load",
            data: {
                id: data.load_detail.id,
                source: data.load_detail.source_city_name,
                destination: data.load_detail.destination?.split("(")[0],
                quantity: data.load_detail.quantity,
                lorry_type_id: NEW_LORRY_TYPES.find((l) => l.label === data.load_detail.lorry_type)?.id,
                lorry_type_text: data.load_detail.lorry_type,
                transporter_name: data.load_detail.destination_city_name,
                oppositeCompanyId: companyId
            }
        });
        // if (isTso) {
        //     trackClick("share_clicked");
        // }
    };

    const {
        isModalOpen: isAdminOptionMenuOpen,
        closeModal: closeAdminOptionMenu,
        toggleModal: toggleAdminOptionMenu
    } = useToggleModalWithLocalState(false);
    const {
        isModalOpen: isLoadDeletionModalOpen,
        openModal: openLoadDeletionModal,
        closeModal: closeLoadDeletionModal
    } = useToggleModalWithLocalState(false);

    const { mutateAsync: deactivateLoad } = useDeactivateLoad();

    const handleDeleteLoad = () => {
        deactivateLoad(
            { loadId: Number(data.load_detail.id) },
            {
                onSuccess: (res) => {
                    toast.success(res.data.message);
                    closeLoadDeletionModal();
                },
                onError: (res) => {
                    toast.error(res.message);
                    closeLoadDeletionModal();
                }
            }
        );
    };

    const { connectC2C } = useGetContactDetails(data.company_detail.id.toString());

    const _onClickGetContactTso = () => {
        /**
         * Use C2C_REFERENCE_TYPE.LOAD_MARKETPLACE once added in backend
         */
        connectC2C({
            callerId: Number(companyId),
            calleeId: Number(data.company_detail.id.toString()),
            referenceId: Number(data.load_detail.id),
            referenceType: C2C_REFERENCE_TYPE.TAGGED_USER_LOAD_BID
        });
        handleTracking?.("call_initiated");
    };

    const AgentDetailsSlot = <AgentBadge type="tso" data={data.agent_detail} />;

    const VerifiedTagSlot = data.load_detail.is_verified ? (
        <Badge text="Verified" borderRadius={20} fillType="contained" type="active" fontSize="xs" />
    ) : (
        <></>
    );

    const C2cBtnSlot = (
        <>
            {data.agent_detail?.phone_number && (
                <IfCanAccess action={ADMIN_OPERATION.tagAgent} alsoCheck={!!data.company_detail.id}>
                    <span className={Styles.callBtn} onClick={_onClickGetContactTso}>
                        <IconWrapper width={32} height={32}>
                            <CallCircle />
                        </IconWrapper>
                    </span>
                </IfCanAccess>
            )}
        </>
    );

    const { sendGAandMoEngageEvent } = useEventTrackerService();

    const handleTracking = (name: string, additionalData: GenericObject = {}) => {
        const eventData = {
            source_screen: evenSourceScreen || "tso_load_market",
            tso_tagged: !!data?.agent_detail?.agent_name,
            ...(!!data?.agent_detail?.agent_name && {
                tagged_tso_id: data?.agent_detail?.user_id
            }),
            ...additionalData,
            load_id: data.load_detail.id,
            is_load_verified: data.load_detail.is_verified,
            from_location: data.load_detail.source,
            to_location: data.load_detail.destination,
            material: data.load_detail.material_type,
            quantity: data.load_detail.quantity,
            required_vehicle_type: data.load_detail.lorry_type_id,
            expected_price: data.load_detail.amount,
            expected_price_type: stringOperations(EXPECTED_AMOUNT_TYPE[data.load_detail.payment_type!] ?? "", [
                "toLowerCase",
                "replaceSpacesWithUnderscore"
            ])
        };

        sendGAandMoEngageEvent({
            name,
            data: eventData
        });
    };

    return (
        <div className={classnames(Styles.main, mobileResponsive && Styles.mobileResponsive)}>
            <div className={Styles.headerContent}>
                <Flex justifyContent="space-between">
                    {data.load_detail.is_tap_n_go ? (
                        <div style={{ marginLeft: "8px" }}>
                            <VahakAssured />
                        </div>
                    ) : data.load_detail.is_verified ? (
                        <VerifiedTag />
                    ) : (
                        <></>
                    )}
                    <Flex alignItems="center" className={Styles.actionBtnWrapper}>
                        <IfCanAccess action={ADMIN_OPERATION.userLoadLorryAction}>
                            <>
                                <Dropdown
                                    isOpen={isAdminOptionMenuOpen}
                                    DropdownToggleBtn={
                                        <div className={Styles.langIconWrapper} style={{ cursor: "pointer" }}>
                                            <DotsMoreIcon />
                                        </div>
                                    }
                                    handleToggle={toggleAdminOptionMenu}
                                    dropdownClassName={Styles.adminOptionDropdown}
                                    dropdownToggleClassName={Styles.adminOptionDropdownToggle}
                                >
                                    <div style={{ marginLeft: "10px" }}>
                                        <Button
                                            fillType="text"
                                            size="s"
                                            onClick={() => {
                                                closeAdminOptionMenu();
                                                openLoadDeletionModal();
                                            }}
                                        >
                                            Remove Load
                                        </Button>
                                    </div>
                                </Dropdown>

                                <Modal
                                    isOpen={isLoadDeletionModalOpen}
                                    onToggle={closeLoadDeletionModal}
                                    showHeader={false}
                                    size="compact"
                                >
                                    Are you sure you want to remove this load from the marketplace?
                                    <Flex gap={10} margin={"15px 0 0 0"}>
                                        <Button fillType="outlined" onClick={closeLoadDeletionModal} size="s">
                                            Cancel
                                        </Button>
                                        <Button fillType="outlined" onClick={handleDeleteLoad} size="s">
                                            Yes, Remove
                                        </Button>
                                    </Flex>
                                </Modal>
                            </>
                        </IfCanAccess>

                        <div className={Styles.shareBtn}>
                            <Button fillType="text" onClick={toggleShareModal} id={`${id}--share`}>
                                <Typography color={Colors.GREY_400} size="xs">
                                    <ShareIcon /> Share
                                </Typography>
                            </Button>
                        </div>
                    </Flex>
                </Flex>
            </div>
            <div className={Styles.bodyContent}>
                <SourceDestination
                    id={id}
                    sourceCity={
                        (data.load_detail.source_locality_name ? data.load_detail.source_locality_name + ", " : "") +
                        data.load_detail.source.split("(")[0]
                    }
                    sourceState={data.load_detail.source.split("(")[1].replace(")", "")}
                    destCity={
                        (data.load_detail.destination_locality_name
                            ? data.load_detail.destination_locality_name + ", "
                            : "") + data.load_detail.destination.split("(")[0]
                    }
                    destState={data.load_detail.destination.split("(")[1].replace(")", "")}
                    distanceInKm={Math.ceil(data.load_detail.distance / 1000)}
                />

                <LineDivider styleType="solid" linearGradient={true} />

                <Flex gap={12} flexDirection="column" margin={"9px 0"}>
                    <MarketplaceLoadLorryQuickInfo
                        id={id}
                        lorryType={data.load_detail.lorry_type_id}
                        type="lorry"
                        tyres={data.load_detail.total_tyres}
                        fontWeight="medium"
                        bodyType={LORRY_BODY_TYPE_MAP[data.load_detail.body_type]}
                        lorrySize={data.load_detail.size}
                        lorrySizeText={data.load_detail.size_text}
                    />
                    <MarketplaceLoadLorryQuickInfo
                        id={id}
                        type="load"
                        fontWeight="medium"
                        material={data.load_detail.material_type}
                        quantity={data.load_detail.quantity}
                        lorryType={data.load_detail.lorry_type_id}
                    />
                    <LoadingTimeBadge
                        id={id}
                        time={
                            data.load_detail.loading_timestamp
                                ? new Date(data.load_detail.loading_timestamp * 1000)
                                : undefined
                        }
                    />
                </Flex>

                <LineDivider styleType="solid" linearGradient={true} />

                <div style={{ margin: "9px 0px 13px 0" }}>
                    <UserProfileInfo
                        id={id}
                        companyId={data.company_detail.id}
                        companyLogo={data.company_detail.logo}
                        name={data.company_detail.name}
                        isBankVerified={data.company_detail.is_bank_verified}
                        isVerified={data.company_detail.is_company_verified}
                        isAadhaarVerified={data.company_detail.is_aadhaar_verified}
                        isGstVerified={data.company_detail.is_gst_verified}
                        isPanVerified={data.company_detail.is_company_verified}
                        isMember={data.company_detail.is_member}
                        rating={data.company_detail.ratings}
                        serviceType={data.company_detail.service_type}
                        handleClickProfile={handleClickProfile}
                        phoneNumber={data?.company_detail?.phone_number}
                        agentTagged={!!data.agent_detail}
                    />
                    <IfCanAccess action={""} allowCsUser>
                        <LineDivider styleType="solid" linearGradient={true} margin="10px 0" />
                        <Flex justifyContent="space-between" alignItems="center" style={{ height: "38px" }}>
                            {AgentDetailsSlot}
                            {VerifiedTagSlot}
                            {C2cBtnSlot}
                        </Flex>
                    </IfCanAccess>
                </div>
            </div>

            <div
                className={classnames(
                    Styles.footerContent
                    // companyId && !data.relevancy_tags.tags && Styles.singleChild
                )}
            >
                <ExpectedPriceAndBidNow
                    id={id}
                    onBidNowClick={onBidNowClick}
                    advanceAmount={data.load_detail.advance_payment_percentage}
                    expectedPrice={data.load_detail.amount}
                    paymentType={data.load_detail.payment_type}
                    pricePerTonPerKm={Number(data.load_detail.price_per_tonne_per_km.toPrecision(3))}
                    additionalCharge={data?.load_detail?.meta_data?.loading_charges_detail?.charges}
                />
                {/* <PeopleViewingCount count={3} id={id} /> */}
                <RelevancyTags tags={data.relevancy_tags.tags} />
            </div>
        </div>
    );
};

export default MarketplaceLoadCard;

export const MarketplaceLoadCardShimmer = ({
    mobileResponsive = true
}: Pick<MarketplaceLoadCardProps, "mobileResponsive">) => {
    return (
        <div
            className={classnames(Styles.main, mobileResponsive && Styles.mobileResponsive)}
            style={{ display: "flex", flexDirection: "column", padding: 20, gap: 8 }}
        >
            <Skeleton type="button" width={50} height={50} />
            <br />
            <Skeleton type="button" />
            <Skeleton type="button" />
            <Skeleton type="button" />
            <br />

            <Skeleton type="button" height={50} />
        </div>
    );
};
